<template>
  <div id="toolbar">
    <div v-if="isLogoRequired && portalId === ''" class="logo">
      <img :src="logo" alt="logo" :height="logoHeight" />
    </div>

    <q-space />
    <div v-if="!signUp" class="row" style="align-items: center">
      <div class="link" @click="showTermsOfUse()">Terms & Conditions</div>
      <div class="link" @click="showPrivacyPolicy()">Privacy Policy</div>
      <BaseButton
        v-if="portalId === '' && isCloudApp"
        label="sign up"
        @click="openSignUp()"
      />
    </div>
    <div v-else class="row" style="align-items: center">
      <div class="link" @click="openSignIn()">Already have an account?</div>
      <BaseButton v-if="signUp" label="sign in" @click="openSignIn()" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Toolbar",

  props: {
    tenantId: {
      type: String,
      default: "",
    },

    portalId: {
      type: String,
      default: "",
    },

    signUp: {
      type: Boolean,
      defult: false,
    },
  },

  computed: {
    ...mapState(["darkTheme"]),

    isLogoRequired() {
      let origin = location.origin;
      if (
        origin === "https://trial.ezofis.com" &&
        this.tenantId === "2" &&
        this.portalId === "4"
      ) {
        return false;
      }
      return true;
    },

    logo() {
      let origin = location.origin;
      if (
        origin === "https://edmsuat.sobhaapps.com" ||
        origin === "https://edms.sobhaapps.com"
      ) {
        return require(`@/assets/logo/sobha-logo.png`);
      } else if (
        origin === "https://ag-appsvc01.azurewebsites.net" ||
        origin === "https://ag-appsvc05.azurewebsites.net"
      ) {
        return require(`@/assets/logo/psg-logo.png`);
      } else if (
        origin === "https://trial.ezofis.com1" &&
        this.tenantId === "2" &&
        this.portalId === "1"
      ) {
        return require(`@/assets/logo/bell-logo.png`);
      } else if (
        origin === "https://trial.ezofis.com" &&
        this.tenantId === "2" &&
        this.portalId === "46"
      ) {
        return require(`@/assets/logo/hovr.png`);
      } else if (
        origin === "https://trial.ezofis.com" &&
        this.tenantId === "23" &&
        this.portalId === "1"
      ) {
        return require(`@/assets/logo/bell-logo.png`);
      } else if (origin === "http://172.16.1.118") {
        return require(`@/assets/logo/kayaku-logo.png`);
      } else {
        return require(`@/assets/logo/logo-${
          this.darkTheme ? "dark" : "light"
        }.png`);
      }
    },

    logoHeight() {
      let origin = location.origin;
      if (
        origin === "https://edmsuat.sobhaapps.com" ||
        origin === "https://edms.sobhaapps.com"
      ) {
        return "36px";
      } else if (
        origin === "https://ag-appsvc01.azurewebsites.net" ||
        origin === "https://ag-appsvc05.azurewebsites.net"
      ) {
        return "48px";
      } else if (
        origin === "https://trial.ezofis.com1" &&
        this.tenantId === "2" &&
        this.portalId === "1"
      ) {
        return "62px";
      } else if (
        origin === "https://trial.ezofis.com" &&
        this.tenantId === "23" &&
        this.portalId === "1"
      ) {
        return "62px";
      } else if (origin === "http://172.16.1.118") {
        return "48px";
      } else {
        return "24px";
      }
    },

    isCloudApp() {
      let origin = location.origin;
      if (
        origin === "https://1app.ezofis.com" ||
        origin === "https://appuat.ezofis.com" ||
        origin === "https://demo.ezofis.com" ||
        origin === "https://trial.ezofis.com" ||
        origin === "http://52.172.32.88" ||
        origin === "http://localhost:8080"
      ) {
        return true;
      } else {
        return false;
      }
    },
  },

  methods: {
    showTermsOfUse() {
      window.open("https://www.ezofis.com/privacy-policy/");
    },

    showPrivacyPolicy() {
      window.open("https://www.ezofis.com/privacy-policy/");
    },

    openSignUp() {
      this.$emit("signUp", true);
    },

    openSignIn() {
      this.$emit("signUp", false);
    },
  },
};
</script>

<style lang="scss" scoped>
#toolbar {
  height: 56px;
  display: flex;
  align-items: center;
  padding: 0px 16px;

  .logo {
    display: flex;
    align-items: center;

    img {
      width: auto;
    }
  }

  .link {
    @extend .text-sm;
    color: var(--icon-color-inverted);
    margin-right: 16px;

    &:hover {
      color: var(--secondary);
      cursor: pointer;
    }
  }
}
</style>
