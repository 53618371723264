<template>
  <ValidationProvider
    v-slot="{ errors }"
    name="email"
    :rules="{ required: true, email: true }"
  >
    <TextField
      :value="value"
      label="email"
      is-mandatory
      :auto-focus="true"
      :is-readonly="isLoading"
      :error="errors[0]"
      class="q-mb-md"
      @input="handleInput"
      @enter="$emit('enter')"
    />
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import TextField from "@/components/common/form/text-field/TextField.vue";

export default {
  name: "Email",

  components: { ValidationProvider, TextField },

  props: {
    value: {
      type: String,
      default: "",
    },

    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    handleInput(value) {
      this.$emit("input", value);
    },
  },
};
</script>

<style lang="scss" scoped></style>
