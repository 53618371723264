<template>
  <div id="features">
    <!-- image -->

    <div v-if="_logo" style="height: 100%; display: flex; place-items: center">
      <img :src="_logo" height="150px" />
    </div>

    <div v-if="!_logo" class="image-container">
      <img :src="blob1" alt="blob" />
      <img :src="blob2" alt="blob" />

      <div
        v-for="subFeature in feature.subFeatures"
        :key="subFeature.id"
        class="sub-feature"
        :style="subFeature.position"
      >
        <div class="avatar" :class="subFeature.color">
          <BaseIcon
            :name="subFeature.icon"
            size="32px"
            color="white"
            class="icon"
          />
        </div>

        <div class="label">{{ subFeature.label }}</div>
      </div>
    </div>

    <!-- ... -->

    <template v-if="!_logo">
      <!-- title -->

      <div class="title">{{ feature.title }}</div>

      <!-- ... -->

      <!-- description -->

      <div class="description">{{ feature.description }}</div>

      <!-- ... -->

      <!-- navigator -->

      <div class="navigator">
        <BaseActionButton
          v-for="(_feature, index) in features"
          :key="_feature.id"
          is-flat
          no-border
          icon="mdi-circle-medium"
          :color="_feature.id === feature.id ? 'secondary' : 'gray'"
          @click="goto(index)"
        />
      </div>

      <!-- ... -->
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Features",

  data() {
    return {
      feature: "",
      features: [
        {
          id: this.$nano.id(),
          title: "Digital Workspace & Low-Code Automation",
          description:
            "Access all your workflows and data in one place with our digital workspace. Simplify your process automation with our low-code platform.",
          subFeatures: [
            {
              id: this.$nano.id(),
              icon: "mdi-archive",
              label: "Workspace Automation",
              color: "primary",
              position: { top: "calc(50% - 172px)", left: "calc(50% - 146px)" },
            },
            {
              id: this.$nano.id(),
              icon: "mdi-cube",
              label: "Portals & Tasks",
              color: "secondary",
              position: {
                top: "calc(50% - 160px)",
                right: "calc(50% - 146px)",
              },
            },
            {
              id: this.$nano.id(),
              icon: "mdi-sitemap",
              label: "Workflows & Forms",
              color: "secondary",
              position: {
                bottom: "calc(50% - 160px)",
                left: "calc(50% - 146px)",
              },
            },
            {
              id: this.$nano.id(),
              icon: "mdi-shape-plus",
              color: "primary",
              label: "Easy Integrations",
              position: {
                bottom: "calc(50% - 172px)",
                right: "calc(50% - 146px)",
              },
            },
          ],
        },
        {
          id: this.$nano.id(),
          title: "Advanced Analytics, Reports & Dashboards",
          description:
            "Transform your workflow data and enterprise contents into actionable insights and make informed decisions to take your business to the next level",
          subFeatures: [
            {
              id: this.$nano.id(),
              icon: "mdi-rocket-launch",
              label: "Powerful Tools",
              color: "primary",
              position: { top: "calc(50% - 172px)", left: "calc(50% - 146px)" },
            },
            {
              id: this.$nano.id(),
              icon: "mdi-chart-areaspline",
              label: "Complete Dashboards",
              color: "secondary",
              position: {
                top: "calc(50% - 160px)",
                right: "calc(50% - 146px)",
              },
            },
            {
              id: this.$nano.id(),
              icon: "mdi-eye-settings",
              label: "Advanced Monitoring",
              color: "secondary",
              position: {
                bottom: "calc(50% - 160px)",
                left: "calc(50% - 146px)",
              },
            },
            {
              id: this.$nano.id(),
              icon: "mdi-database",
              color: "primary",
              label: "Big Data Wrangling",
              position: {
                bottom: "calc(50% - 172px)",
                right: "calc(50% - 146px)",
              },
            },
          ],
        },
        {
          id: this.$nano.id(),
          title: "Artificial Intelligence and Machine Learning",
          description:
            "Stay ahead of the competition with our AI and machine learning enterprise tools. Unlock data-driven insights and automation to revolutionize your business.",
          subFeatures: [
            {
              id: this.$nano.id(),
              icon: "mdi-robot",
              label: "Ai Powered Automations",
              color: "primary",
              position: { top: "calc(50% - 172px)", left: "calc(50% - 146px)" },
            },
            {
              id: this.$nano.id(),
              icon: "mdi-image",
              label: "Image Recognition",
              color: "secondary",
              position: {
                top: "calc(50% - 160px)",
                right: "calc(50% - 146px)",
              },
            },
            {
              id: this.$nano.id(),
              icon: "mdi-cloud",
              label: "Augmented Ecosystem",
              color: "secondary",
              position: {
                bottom: "calc(50% - 160px)",
                left: "calc(50% - 146px)",
              },
            },
            {
              id: this.$nano.id(),
              icon: "mdi-state-machine",
              color: "primary",
              label: "Machine Learning",
              position: {
                bottom: "calc(50% - 172px)",
                right: "calc(50% - 146px)",
              },
            },
          ],
        },
      ],
    };
  },

  computed: {
    ...mapState(["darkTheme"]),

    blob1() {
      return require(`@/assets/auth/blob-primary-${
        this.darkTheme ? "dark" : "light"
      }.svg`);
    },

    blob2() {
      return require(`@/assets/auth/blob-secondary-${
        this.darkTheme ? "dark" : "light"
      }.svg`);
    },

    _logo() {
      let origin = location.origin;
      if (
        origin === "https://edmsuat.sobhaapps.com" ||
        origin === "https://edms.sobhaapps.com"
      ) {
        return require(`@/assets/logo/sobha-mark-logo.png`);
      } else {
        return "";
      }
    },
  },

  created() {
    this.feature = this.features[0];
  },

  methods: {
    goto(featureIdx) {
      this.feature = this.features[featureIdx];
    },
  },
};
</script>

<style lang="scss" scoped>
#features {
  flex: 1;
  height: calc(100vh - 92px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .image-container {
    width: 360px;
    height: 360px;
    margin-bottom: 36px;
    animation: float 3s ease-in-out infinite;
    position: relative;

    img {
      width: 100%;
      height: auto;
      opacity: 0.5;
      position: absolute;
      top: 0;
      left: 0;
    }

    .sub-feature {
      width: 140px;
      padding: 24px;
      border-radius: 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: absolute;
      box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.25) !important;

      .avatar {
        height: 56px;
        width: 56px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        margin-bottom: 16px;

        &.primary {
          background-color: var(--primary);
        }

        &.secondary {
          background-color: var(--secondary);
        }
      }

      .label {
        font-weight: 600;
        text-align: center;
      }
    }

    @keyframes float {
      0% {
        transform: translate(0, 0px);
      }
      50% {
        transform: translate(0, -15px);
      }
      100% {
        transform: translate(0, 0px);
      }
    }
  }

  .title {
    @extend .text-xl;
    font-weight: bold;
    margin-bottom: 8px;
    color: var(--title-1-color);
  }

  .description {
    width: 75%;
    text-align: center;
    color: var(--icon-color);
  }

  .navigator {
    display: flex;
    align-items: center;
    margin-top: 36px;
    cursor: pointer;
  }

  .custom-image-container {
    animation: float 3s ease-in-out infinite;
    position: relative;
  }
}

.theme-light .sub-feature {
  background-color: var(--component-bg-color);
}

.theme-dark .sub-feature {
  background-color: var(--component-bg-color-inferior);
}
</style>
