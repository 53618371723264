<template>
  <div id="authenticationSignUP">
    <div class="content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "AuthenticationSignUP",
};
</script>

<style lang="scss" scoped>
#authenticationSignUP {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  .content {
    width: 440px;
    background-color: var(--component-bg-color);
    border-radius: 4px;
    padding: 18px;
  }
}
</style>
