<template>
  <div
    :class="{
      'sign-up-btns-container': signUp,
      'sign-in-btns-container': !signUp,
    }"
  >
    <template v-for="btn in socialAuthBtns">
      <div
        v-if="socialLogin || checkTenant(btn.label)"
        :key="btn.id"
        :class="{ 'sign-up-btn': signUp, 'sign-in-btn': !signUp }"
        @click="btn.action"
      >
        <img
          :src="require(`@/assets/auth/${btn.label}.png`)"
          :alt="btn.label"
          height="20"
          width="20"
          class="q-mr-sm"
        />
        <div>{{ btn.label }}</div>
      </div>
    </template>
  </div>
</template>

<script>
import * as Msal from "msal";
import { auth } from "@/api/factory.js";
export default {
  name: "SocialAuths",
  props: {
    tenantId: {
      type: String,
      default: "0",
    },

    loggedFrom: {
      type: String,
      default: "WEB",
    },

    socialLogin: {
      type: Boolean,
      default: false,
    },

    socialLoginList: {
      type: Array,
      default: () => [],
    },

    signUp: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      socialAuthBtns: [
        {
          id: this.$nano.id(),
          label: "google",
          action: this.googleSignIn,
        },
        {
          id: this.$nano.id(),
          label: "microsoft",
          action: this.microsoftSignIn,
        },
        // {
        //   id: this.$nano.id(),
        //   label: "facebook",
        // },
      ],
    };
  },

  methods: {
    checkTenant(value) {
      let origin = location.origin;
      if (
        origin === "https://edmsuat.sobhaapps.com" ||
        origin === "https://edms.sobhaapps.com"
      ) {
        if (value === "microsoft") {
          let found = this.socialLoginList.find((row) =>
            row.toLowerCase().includes(value)
          );
          if (found) {
            return true;
          }
          return false;
        }
      } else {
        let found = this.socialLoginList.find((row) =>
          row.toLowerCase().includes(value)
        );
        if (found) {
          return true;
        }
        return false;
      }
    },

    async googleSignIn() {
      // Google Authentication
      const googleUser = await this.$gAuth.signIn();
      const user = googleUser.getBasicProfile();
      console.log(user);

      const values = Object.values(user);
      console.log(values);

      let email = values[5];
      // Google Authentication
      this.isLoading = true;

      if (this.signUp) {
        this.$emit("logged", "GOOGLE", values);
        return;
      }

      const payload = {
        tenantId: this.tenantId,
        email: email,
        loggedFrom: this.loggedFrom,
      };

      const { error, data, status } = await auth.socialLogin(payload);

      if (error) {
        this.error = error;
        this.isLoading = false;
        return;
      }

      if (status === 300) {
        this.$emit("tenantCheck", email, data);
      } else {
        this.$emit("logged");
      }
    },

    async microsoftSignIn() {
      let clientId = "315d2d09-9633-419e-8e85-18b747eb5be5";
      let origin = location.origin;
      if (origin === "https://edmsuat.sobhaapps.com") {
        clientId = "9db78754-f584-459d-a04d-f5a6d9b1e2cf";
      } else if (origin === "https://edms.sobhaapps.com") {
        clientId = "a403995e-94b6-4805-bee7-2fa606a751bd";
      }
      // Microsoft Authentication
      const mAuth = new Msal.UserAgentApplication({
        auth: {
          clientId: clientId,
          redirectUri: origin,
        },
      });
      await mAuth.loginPopup({
        scopes: ["user.read"],
      });
      const user = mAuth.getAccount();
      let email = user.userName;
      // Microsoft Authentication
      console.log(email);

      this.isLoading = true;

      if (this.signUp) {
        this.$emit("logged", "MICROSOFT", user);
        return;
      }

      const payload = {
        tenantId: this.tenantId,
        email: email,
        loggedFrom: this.loggedFrom,
      };

      const { error, data, status } = await auth.socialLogin(payload);

      if (error) {
        this.error = error;
        this.isLoading = false;
        return;
      }

      if (status === 300) {
        this.$emit("tenantCheck", email, data);
      } else {
        this.$emit("logged");
      }

      this.$emit("logged");
    },
  },
};
</script>

<style lang="scss" scoped>
.sign-up-btns-container {
  display: flex;
  flex-direction: column;
}

.sign-up-btn {
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  color: var(--icon-color) !important;
  font-weight: 600;
  text-transform: capitalize;
  cursor: pointer;
  border: var(--component-bg-color-inferior) 1px solid;
  margin-top: 10px;
}

.sign-in-btns-container {
  display: flex;
  align-items: center;
}

.sign-in-btn {
  flex: 1;
  height: 44px;
  // padding: 0px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: var(--component-bg-color-inferior);
  color: var(--icon-color) !important;
  font-weight: 600;
  text-transform: capitalize;
  cursor: pointer;

  &:nth-child(2) {
    margin-left: 8px;
  }
}
</style>
