<template>
  <div id="auth-layout">
    <Toolbar
      :tenant-id="tenantId"
      :portal-id="portalId"
      :sign-up.sync="signUp"
      @signUp="getSignUp"
    />

    <div class="row">
      <Features />

      <Authentication v-if="!signUp">
        <slot></slot>
      </Authentication>
      <AuthenticationSignUp v-else>
        <slot></slot>
      </AuthenticationSignUp>
    </div>

    <div class="footer">&copy; 2024 All Rights Reserved</div>
  </div>
</template>

<script>
import Toolbar from "./components/Toolbar.vue";
import Features from "./components/Features.vue";
import Authentication from "./components/Authentication.vue";
import AuthenticationSignUp from "./components/AuthenticationSignUp.vue";

export default {
  name: "AuthLayout",

  components: { Toolbar, Features, Authentication, AuthenticationSignUp },

  props: {
    tenantId: {
      type: String,
      default: "",
    },

    portalId: {
      type: String,
      default: "",
    },
  },

  computed: {
    signUp() {
      if (location.pathname.indexOf("/signUp") > -1) {
        return true;
      }
      return false;
    },
  },

  methods: {
    getSignUp(value) {
      if (value) {
        this.$router.push({ name: "signup" });
      } else {
        this.$router.push({ name: "signin" });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#auth-layout {
  .footer {
    @extend .text-sm;
    color: var(--icon-color-inverted);
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
